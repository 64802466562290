import React, { useState } from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/Seo";
import { SectionPost } from "../../view/blog/sectionPost";
import Layout from "../../templates/MainTemplate";
import { MainHome } from "../../view/blog/styledPost";
import { useEffect } from "react";
import { useMidiaPage } from "../../hooks/methods/midia";


export default function Midia({ location }) {
  const [pageUpsell, setPageUpsell] = useState(1);
  const [skipPage, setSkipPage] = useState(0);

  useEffect(() => {
    setSkipPage(pageUpsell * 16 - 16);
  }, [pageUpsell]);

  const {data: pagePostData, isSuccess, isLoading}  = useMidiaPage();

  const hashPage = location ? location?.hash : null;

  return (
    <Layout>
      <MainHome>
        {(isSuccess && pagePostData && !isLoading) && (
          <>
            <h1>{pagePostData?.cinnectaNaMidiaPage?.title}</h1>
            <h2>{pagePostData?.cinnectaNaMidiaPage?.subtitle}</h2>
          <SectionPost
              details={pagePostData?.cinnectaNaMidiaPage}
              hashPage={hashPage}
              location={location}
              isMidiaPage
            /> 
          </>
        )}
      </MainHome>
    </Layout>
  );
}

export const Head = ({
  data: {
    cmsCinnectaNaMidiaPage: {
      seoTitle,
      seoDescription,
      seoKeywords,
      seoImage: { url },
    },
  },
}) => {
  const keywordsList = seoKeywords.toString();
  return (
    <Seo
      title={seoTitle}
      description={seoDescription}
      keywords={keywordsList}
      url={url}
    />
  );
};

export const query = graphql`
  query PageQuery {
    cmsCinnectaNaMidiaPage {
      seoTitle
      seoDescription
      seoKeywords
      seoImage {
        url
      }
      seoRobots
    }
  }
`;
